body{
  min-height: 100vh;
  height: 100%;
  margin: 0;
}
pre {
  margin:0;
}
.App,#root{
  height: 100%;
  min-height: 100vh;
}
.App {
  text-align: center;
}
select:focus{
  box-shadow: 0 0 0 0px rgb(16 112 202 / 14%), inset 0 0 0 1px rgb(67 90 111 / 30%), inset 0 0 0px 0 rgb(67 90 111 / 14%) !important;
}
hr {
  opacity:0.2;
}
label, input:focus, a:focus{
  outline: 0!important;
  box-shadow: none!important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-mobile {
  display:none;
}
.d-flex {
  display: flex;
}
@media(max-width:768px){
  .d-mobile {
    display:flex;
  }
  .d-mobile-none {
    display:none !important;
  }
  
}
.default-hr{
  width: 100%;
  background: #efefef;
  margin: 0;
}
.default-button{
  background-color: #1E9189 !important;
  border: 0 !important;
  color:#fff!important;
  border-radius: 20px !important;
}
.minimal-button{
  color:#1E9189!important;
  border-radius: 20px !important;
}

@media(max-width:993px){
  .default-button, .minimal-button{
    font-size:10px!important;
  }
}
.minimal-dark-button>span{
  display:flex;
  align-items: center;
}
.minimal-dark-button span span{
  color:#5c5c5c!important;
  font-size: 12px;
  padding-top: 1px;
}
.minimal-dark-button svg{
  color:#1E9189!important;
}
.checkbox-primary{
  padding:4px 5px;
}
.checkbox-primary>input:checked + div {
  background-color: #1E9189 !important;
  box-shadow:unset !important;
  border-radius: 4px !important;
} 
.checkbox-primary>div {
  background-image: none !important;
  outline: 0 !important;
}
.checkbox-primary>span {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 15px;
  letter-spacing: normal;
  text-align: left;
  color: #5c5c5c;
}


/* a.Tab{
  font-weight: 300;
  color:#5c5c5c !important;
}
a.Tab[aria-current="page"]{
  font-weight: 400;
  color:#1E9189!important;
  color:#202124!important;
} */

a.Tab[aria-current="page"]{
  color:#1E9189!important;
}
.TabNavigationPrimary{ 
  border-bottom: 0.5px solid #efefef;
  margin-bottom: 9px;
}
.TabNavigationPrimary a.Tab{
  position: relative;
  padding-left:0;
  padding-right:0;
  margin: 0 8px;
}
.TabNavigationPrimary a.Tab:first-of-type{
  margin-left:0;
}
.TabNavigationPrimary a.Tab[aria-current="page"]::after{
  content:" ";
  position: absolute;
  background:#1E9189!important;
  right:0;
  left:0;
  height: 2px;
  bottom:-1px;
  border-radius: 3px;
}
/* *[role=dialog]>*{
  padding-left:0 !important;
  padding-right:0 !important;
} */

.default-tag-input{
  align-items: flex-start!important;
  border-radius: 14px!important;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  outline: 0!important;
  /* width: 220px; */

  max-width: max-content;
  width: auto;
  min-width: 196px;
} 
.default-tag-input[aria-activedescendant]{
  box-shadow:inset 0 0 2px rgb(67 90 111 / 8%), inset 0 0 0 1px #1e9189d8, 0 0 0 3px rgb(16 112 202 / 8%)!important;
}
